import React, { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Box,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { auth } from '../firebaseConfig';
import { ReactComponent as LogoColorBorderWhite } from '../assets/logo/color-border-white.svg';
import { UserContext } from '../contexts/UserContext';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useContext(UserContext);

  const isOnLandingPage = location.pathname === '/';

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isOnLandingPage && !user) {
    return null; // Do not render header on landing page when not logged in
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ paddingY: 1 }}>
        <LogoColorBorderWhite
          width={40}
          height={40}
          style={{ cursor: 'pointer', marginRight: '8px' }}
          onClick={() => navigate('/')}
          aria-label="CheeseTax Logo"
        />
        <Typography
          variant="h6"
          color="#fff"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          CheeseTax
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {user && !isOnLandingPage && location.pathname !== '/dashboard' && (
            <Typography
              component={RouterLink}
              to="/"
              color="inherit"
              sx={{
                textDecoration: 'none',
                mx: 2,
                paddingY: 1,
                display: { xs: 'none', md: 'block' },
              }}
            >
              Home
            </Typography>
          )}
          {user && (
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={handleMenu}
                sx={{ ml: 2 }}
                aria-label="Account menu"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
